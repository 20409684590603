import React from "react";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { useLinkbuildingCampaignStore, useProjectStore } from "../../../store";
import { IProject } from "../../../interfaces/project.interface";
import { ApiResponse } from "../../../api/base/api-response";
import { ICampaign } from "../models/campaign";
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";


const EditCampaignDialogComponent = ({ setDialogVisible }) => {
    
    const campaignStore = useLinkbuildingCampaignStore();
    const [campaignData, setCampaignData] = React.useState<ICampaign>();
    React.useEffect(() => {
        let campaign = campaignStore.selectedCampaign;
        setCampaignData(campaign);
        setCampaignName(campaign.name);
        setCampaignDescription(campaign.description);
        setCampaignStartDate(campaign.startDate);
        setCampaignEndDate(campaign.endDate);
        setLabels(campaign.labels);
    }, []);
    
    // STORES.
    const project: IProject = useProjectStore(state => state.selectedProject)

    
    // FORM FIELDS
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
    const [campaignName, setCampaignName] = React.useState<string>('');
    const [campaignDescription, setCampaignDescription] = React.useState<string>('');
    const [campaignStartDate, setCampaignStartDate] = React.useState<Date>(new Date());
    const [campaignEndDate, setCampaignEndDate] = React.useState<Date>(new Date());
    const [labels, setLabels] = React.useState<string>('');

    // BUTTON ACCTIONS
    const submitEditCampaignForm = () => {
        // setFormIsSubmitting(true);

        const linkbuildingService = new LinkbuildingService();
        linkbuildingService.updateCampaign(project.id, {
            id: campaignData?.id,
            name: campaignName,
            description: campaignDescription,
            startDate: campaignStartDate,
            endDate: campaignEndDate,
            labels: labels,
        }).then((apiResponse: ApiResponse<ICampaign>) => {
            if (apiResponse.hasError()) {
                // Todo: implement error handling.
            } else {
                apiResponse.getBody().then((data) => {
                    campaignStore.setSelectedCampaign(data);
                    location.reload();
                });
            }
        })
    }

    return (
        <div style={{ width: '350px' }}>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Naam:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
            </div>

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Omschrijving:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={campaignDescription} onChange={(e) => setCampaignDescription(e.target.value)} />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Start datum:</p>
                <Calendar
                    style={{ width: '100%' }}
                    value={new Date(campaignStartDate)}
                    onChange={(e) => {setCampaignStartDate(e.target.value as Date )}}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Eind datum:</p>
                <Calendar
                    style={{ width: '100%' }}
                    value={new Date(campaignEndDate)}
                    onChange={(e) => {setCampaignEndDate(e.target.value as Date )}}
                />
            </div>

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Labels <small>, seperated</small>:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={labels} onChange={(e) => setLabels(e.target.value)} />
            </div>


            <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => setDialogVisible()}/>

                {
                    formIsSubmitting ?
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) :
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submitEditCampaignForm()} />)
                }
                
             </div>
        </div>
    )
}

export default EditCampaignDialogComponent;

//TOOD merge with create campaign Dialog