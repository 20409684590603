
import { TabPanel, TabView } from "primereact/tabview";
import Navbar from "../../../../components/navbar/navbar.component";
import React from "react";
import { LinkbuildingService } from "../../../../api/linkbuilding-service";
import useLinkbuildingCampaignStore from "../../../../store/linkbuilding-campaign-store/linkbuilding-campaign-store";
import useProjectStore from "../../../../store/project-store/project-store";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IProject } from "../../../../interfaces/project.interface";
import BudgetFormDialog from "../../components/budget-form-dialog";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ICampaignBudget, ICampaignBudgetOverview } from "../../models/campaign";
import { error } from "console";
import { set } from "react-hook-form";
import { BlinkBlur } from "react-loading-indicators";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./linkbuilding-budget.styles.scss";
import { useNavigate } from "react-router-dom";
import "../../../../assets/styles/light-theme.scss";

const LinkbuildingBudget = () => {

    const [activeIndex, setActiveIndex] = React.useState(0);
    const linkbuildingService = new LinkbuildingService();
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const campaignStore = useLinkbuildingCampaignStore();
    const [selectedBudget, setSelectedBudget] = React.useState<ICampaignBudget>({} as ICampaignBudget);
    const [budgetOverviewLoading, setBudgetOverviewLoading] = React.useState(true);
    const [budgetEditLoading, setBudgetEditLoading] = React.useState(true);
    const [budgetOverviews, setBudgetOverviews] = React.useState<ICampaignBudgetOverview[]>([]);
    const [budgetDialog, setBudgetDialog] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
            activateBudgetEdit();
            activateBudgetOverview()
        }, []);

    const loadPanelData = async (index: any) => {
        if (index.index === 0) {
            activateBudgetEdit();
        } else if (index.index === 1) {
            activateBudgetOverview();  
        }
    }

    const activateBudgetEdit = async () => {
        setActiveIndex(0);
        setBudgetEditLoading(true);
        linkbuildingService.getCampaignBudgets(selectedProject.id, campaignStore.selectedCampaign.id).then((response) => {
            if(response.hasError()){
                console.error(response.getError());
                //TODO: Show error message
                return;
            }else{
                response.getBody().then((data) => {
                    // setBudgets(data.data);
                    console.log(campaignStore.selectedCampaign);
                    campaignStore.setSelectedCampaign({
                        ...campaignStore.selectedCampaign,
                        budgets: data.data
                    })
                    setBudgetEditLoading(false);
                });
            }
        });
    }    


    const btnNewBudgetClick = () => {
        console.log('new budget');
        setBudgetDialog(true);
    }

    const activateBudgetOverview = async () => {
        setActiveIndex(1);
        setBudgetOverviewLoading(true);
        linkbuildingService.getCampaignBudgetsOverview(selectedProject.id, campaignStore.selectedCampaign.id).then((response) => {
            if(response.hasError()){
                console.error(response.getError());
            }
            else{
                response.getBody().then((data) => {
                    setBudgetOverviews(data.data);
                    console.log(data.data);
                    setBudgetOverviewLoading(false);
                });
            }
        });
    }

    const editBudgetBtn = (budget: ICampaignBudget) => {
        setSelectedBudget(budget);
        setBudgetDialog(true);
    }

    const deleteBudgetBtn = (budget: ICampaignBudget) => {
        console.log('delete budget');
        // TODO: Implement delete budget
    }

    const budgetEditHeader = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Budget</h5>
            <Button 
                icon="pi pi-plus" 
                className="p-button p-button-success mr-2" 
                onClick={() => btnNewBudgetClick()}
                value="Create a new budget" />
        </div>
    )

    const budgetBodyTemplate = (rowData: any) => {
        return (
            <div>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button p-button-rounded p-button-text" 
                    onClick={() => editBudgetBtn(rowData)} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button p-button-rounded p-button-text" 
                    onClick={() => deleteBudgetBtn(rowData)} />
            </div>
        );
    }

    const budgetOverviewHeaderTemplate = (budgetOverview: ICampaignBudgetOverview) => {
        let startDate = new Date(budgetOverview.start);
        return (<>
            <table style={{ width: '100%' }}>
                <td>{startDate.toLocaleString('default', { month: 'long' }) + " - " + startDate.getFullYear()}</td>
                <td>{budgetOverview.budget}</td>
                <td>{budgetOverview.budgetSpend}</td>
                <td>{budgetOverview.budgetReserved}</td>
                <td>{budgetOverview.budgetOver}</td>
                <td>{budgetOverview.linkPlacements.length}</td>
            </table>
            
        </>)
    }

    const viewLinkPlacementBody = (rowData: any) => {
        return (
        <Button onClick={() => navigate(`/linkbuilding/linkplacement/${rowData.id}`)} label="View linkplacement" className="p-button-primary" style={{ width: '100%' }} />
        )
    }
    const publishDateTemplate = (rowData: any) => {
        let date = new Date(rowData.publishDate);
        return date.toLocaleDateString();
    }
    return (
        <>
            <Navbar activeTool={'Linkbuilding'} activePage={'Budget'} />
            <div className="layout-application-window" style={{ padding: '0px 40px' }} >
                <TabView onTabChange={(e) => loadPanelData(e)} activeIndex={activeIndex}>
                    <TabPanel header="Budget">
                        <div style={{width: '100%'}}>
                            { 
                                budgetEditLoading 
                            ?
                                <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
                            :
                                <DataTable 
                                    value={campaignStore.selectedCampaign.budgets}
                                    header={budgetEditHeader}
                                    className="p-datatable-sm p-datatable-gridlines"
                                    dataKey="id"
                                    showGridlines={true}
                                    stripedRows={true}
                                    style={{width: '100%'}}
                                    paginator
                                    rows={20}
                                >
                                    <Column field="startDate" header="Start date" sortable></Column>
                                    <Column field="endDate" header="End Date" sortable></Column>
                                    <Column field="price" header="Price" sortable></Column>
                                    <Column body={budgetBodyTemplate} ></Column>
                                </DataTable>
                            }
                        </div>
                    </TabPanel>
                    <TabPanel header="Budget overview">
                        
                        <div style={{width: '100%'}}>
                            {
                                budgetOverviewLoading
                                ?
                                    <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
                                :
                                    <div>
                                        <table className="budgetOverview">
                                            <tr>
                                                <th>Period</th>
                                                <th>Budget</th>
                                                <th>Budget spend</th>
                                                <th>Budget reserved</th>
                                                <th>Budget over</th>
                                                <th>Amount linkplacements</th>
                                            </tr>
                                        </table>
                                        <Accordion>
                                            { 
                                                budgetOverviews?.map(function(budgetOverview, index){
                                                    return (
                                                        <AccordionTab className="budgetOverview" header={budgetOverviewHeaderTemplate(budgetOverview)} key={index}>
                                                            <DataTable 
                                                                value={budgetOverview.linkPlacements} 
                                                                className="p-datatable-sm p-datatable-gridlines" 
                                                                dataKey="id" showGridlines={true} 
                                                                stripedRows={true} 
                                                                style={{width: '100%'}} paginator rows={20}>
                                                                <Column field="placedOnUrl" header="Url" sortable></Column>
                                                                <Column field="price" header="Price" sortable></Column>
                                                                <Column field="status" header="Status" sortable></Column>
                                                                <Column field="publishDate" header="Publish Date" sortable body={publishDateTemplate}></Column>
                                                                <Column header="Actions" body={viewLinkPlacementBody}></Column>
                                                            </DataTable>
                                                        </AccordionTab>
                                                    )
                                                })
                                            }
                                            
                                        </Accordion>
                                    </div>
                            }
                        </div>
                    </TabPanel>
                </TabView>


                <Dialog header="Budgets" visible={budgetDialog} onHide={() => { setBudgetDialog(false) }}>
                    <BudgetFormDialog setDialogVisible={setBudgetDialog} budget={selectedBudget} />
                </Dialog>


                {/* This confirm-dialog is empty by intent, it's filled by the confirm function. */}
                <ConfirmDialog />
            </div>
        </>
    );
}

export default LinkbuildingBudget;
