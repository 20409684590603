import React from "react";
import { ICampaignBudgetOverview } from "../models/campaign"
import { set } from "react-hook-form";
import { Chart } from "primereact/chart";

const BudgetChart = ({campaignBudgets}) => {

    const [budgets, setBudgets] = React.useState<ICampaignBudgetOverview[]>([]);
    const [currentMonthBudgets, setCurrentMonthBudgets] = React.useState<ICampaignBudgetOverview[]>([]);
    const [previousMonthBudgets, setPreviousMonthBudgets] = React.useState<ICampaignBudgetOverview[]>([]);
    const [currentMonthChart, setCurrentMonthChart] = React.useState<any>();
    const [previousMonthChart, setPreviousMonthChart] = React.useState<any>();

    const ICampaignBudgetToChart = (budget: ICampaignBudgetOverview) => {
        
        let budgetOverDisplay = budget.budgetOver;
        if(budget.budgetOver < 0){
            budgetOverDisplay = 0;
        }
        const data = {
            labels: ['Spend', 'Reserved', 'Over'],
            datasets: [
                {
                    data: [budget.budgetSpend, budget.budgetReserved, budgetOverDisplay],
                    backgroundColor: [
                        'rgb(230, 9, 116)', 
                        'rgb(63, 95, 235)', 
                        'rgb(33, 176, 100)', 
                    ],
                }
            ]
        }
        return data;
    }


    React.useEffect(() => {
        setBudgets(campaignBudgets);
        let currentMonth = new Date().getMonth();
        let currentYear = new Date().getFullYear();
        let previousMonth = currentMonth - 1;
        let previousYear = currentYear;
        if(previousMonth < 0){
            previousMonth = 11;
            previousYear = currentYear - 1;
        }
        let currentMonthBudgets = campaignBudgets.filter(budget => new Date(budget.start).getMonth() === currentMonth && new Date(budget.start).getFullYear() === currentYear);
        let previousMonthBudgets = campaignBudgets.filter(budget => new Date(budget.start).getMonth() === previousMonth && new Date(budget.start).getFullYear() === previousYear);
        console.log(previousMonthBudgets);   
        console.log(campaignBudgets)
        if(previousMonthBudgets.length != 0){
            setPreviousMonthBudgets(previousMonthBudgets);
            setPreviousMonthChart(ICampaignBudgetToChart(previousMonthBudgets[0]));
        }   
        if(currentMonthBudgets.length != 0){
            setCurrentMonthBudgets(currentMonthBudgets);
            setCurrentMonthChart(ICampaignBudgetToChart(currentMonthBudgets[0]));
        }


    }, [campaignBudgets]);
    
    return (
        <div className="body card flex justify-content-center" style={{display: "flex", justifyContent: "center", }}>
            <div className="budget-chart">            
                <div className="card flex justify-content-center">
                    { currentMonthBudgets.length != 0 && <h2 className="text-center">Current month</h2>}
                    <Chart type="pie" data={currentMonthChart} className="w-full md:w-30rem" />
                </div>
            </div>
            <div className="budget-chart">
                <div className="card flex justify-content-center">
                    { previousMonthBudgets.length != 0 && <h2 className="text-center">Last Month</h2>}
                    <Chart type="pie" data={previousMonthChart} className="w-full md:w-30rem" />
                </div>
            </div>
        </div>
    )
}
export default BudgetChart;