/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from "react";
import { useNavigate } from "react-router-dom";
import './application-card.styles.scss';


const ApplicationCardIcon = ({ icon, iconColor }) => {
    return (
        <div style={{ backgroundColor: '#fff', borderRadius: '50%', height: '100px', width: '100px', border: '1px solid rgb(197, 203, 212)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
            <i className={ icon } style={{ fontSize: '22px', fontWeight: '800', color: `${iconColor}` }}/>
        </div>
    );
};

const ApplicationCard = ({ icon, iconColor, title, description, link }) => {

    const navigate = useNavigate();

    const onCardClick = () => {
        navigate(link);
    }

    return (
        <div style={{ marginLeft: '15px', marginRight: '15px', marginTop: '20px', cursor: "pointer"}} onClick={onCardClick}>
            {/* The "picture" part of the card */}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '250px', height: '200px', backgroundColor: `${iconColor}`, borderTopLeftRadius: '5px', borderTopRightRadius: '5px', padding: '15px'}}>
                <ApplicationCardIcon icon={icon} iconColor={iconColor} />
                <h1 style={{ fontWeight: '500', fontSize: '18px', marginTop: '20px' }}>{title}</h1>
            </div>


            {/* The "description" part of the card */}
            <div style={{ backgroundColor: '#fff', padding: '15px', width: '250px', height: '100px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}>
                <p  style={{ fontWeight: '500', fontSize: '13px', color: 'gray' }}>{description}</p>
            </div>

        </div>

    );
};

export default ApplicationCard;