import React from "react";
import { IPartner, IWebsite, IWebsiteLinkPlacement } from '../models/linkplacement';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import "./display-website.styles.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { Tooltip } from 'primereact/tooltip';
import { useProjectStore } from "../../../store";
import { IProject } from "../../../interfaces/project.interface";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { ApiResponse } from "../../../api/base/api-response";
import { PaginatedResponse } from "../../../api/base/paginated-response";
import { set } from "react-hook-form";
import { IContact } from "../models/contact";


const DisplayPartnerComponent = ( partnerInputData:any, editModeInputData:boolean=false) => {
    
    const navigate = useNavigate();
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const linkbuildingService = new LinkbuildingService();
    
    const [partner, setPartner] = React.useState<IPartner>({} as IPartner);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    React.useEffect(() => {
        partnerInputData.partner.contacts = [];
        console.log(partnerInputData.partner);
        setPartner(partnerInputData.partner);
        // if(website.domainUrl != websiteInputData.website.domainUrl){
        //     setWebsite(websiteInputData.website);
        //     fetchWebsiteLinkplacements(websiteInputData.website);
        //     setEditMode(editModeInputData);
        // }
    }, [partnerInputData.partner]);


    // API CALLS
    const fetchPartnerLinkplacements = (fetchWebsite) => { //TODO FIXEN AANMAKEN IN BACKEND
        // linkbuildingService.getWebsiteLinkplacements(selectedProject.id, fetchWebsite.id).then((apiResponse: ApiResponse<PaginatedResponse<IWebsiteLinkPlacement[]>>) => {
        //     if (!apiResponse.hasError()) {
        //         apiResponse.getBody().then((body) => {
        //             for (let i = 0; i < body.data.length; i++) {
        //                 body.data[i].publishDate = new Date(body.data[i].publishDate);
        //             }
        //             const updatedWebsite = {
        //                 ...fetchWebsite,
        //                 linkplacements: body.data,
        //             };
        //             setWebsite(updatedWebsite);
        //             linkbuildingService.getWebsiteContacts(selectedProject.id, fetchWebsite.id).then((apiResponse: ApiResponse<PaginatedResponse<IContact[]>>) => {
        //                 if (!apiResponse.hasError()) {
        //                     apiResponse.getBody().then((body) => {
        //                         const updatedWebsiteContacts = {
        //                             ...updatedWebsite,
        //                             contacts: body.data,
        //                         };
        //                         setWebsite(updatedWebsiteContacts); 
        //                     });
        //                 }
        //             });
        //         });
        //     }
        // }
    // )
    };  


    // BUTTON CLICKS

    const btnEditPartner = () => {
        console.log('Edit partner');
    }


    return (
        <div style={{ width: '100', display: 'flex', flexDirection: 'column' }}>
            <Card className="linkbuilding-website" title={partner.name} subTitle=''>
                <div className="linkbuilding-website-row">
                    <div className="linkbuilding-website-info">
                        <div className="linkbuidling-website-info-scores" style={{ minWidth: '100%', maxWidth: '100%', marginRight: '5%', display: 'flex', flexDirection: 'column', paddingLeft: '5px', borderBottom: '1px solid rgba(0, 0, 0, .1) ' }}>                            
                            {/*  Property List  */}
                            <div style={{ borderTop: '1px solid rgba(0, 0, 0, .1)', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '50px', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                                <p>
                                    <span><i className='fa-solid fa-database' style={{ color: 'rgba(27,118,183,0.76)' }} /></span>
                                    <span style={{ marginLeft: '10px' }}>Cijfers en data</span>
                                </p>
                                { editMode ?
                                    <Button onClick={btnEditPartner} label="Edit partner" className="p-button-primary" style={{ marginLeft: 'auto' }} />
                                    :
                                    <></>
                                }
                            </div>
                            <p style={{marginBottom: '10px'}}>{partner?.comment}</p>
                        </div>
                    </div>
                    <div className="linkbuilding-website-linkplacements">
                        <div className="linkbuilding-website-linkplacements-header">
                            <div style={{ borderTop: '1px solid rgba(0, 0, 0, .1)', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '50px', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                                <p>
                                    <span><i className='fa-solid fa-link' style={{ color: 'rgba(27,118,183,0.76)' }} /></span>
                                    <span style={{ marginLeft: '10px' }} className="recent-linkplacement">Recente linkplacements </span>
                                    <Tooltip 
                                        content="Alle linkplacements bij dezelfde klant, in de afgelopen 2 jaar & alle linkplacements afgelopen jaar" 
                                        position="top" 
                                        style={{marginLeft: '10px'}}
                                        target=".recent-linkplacement">
                                    </Tooltip>
                                </p>
                            </div>                            
                        </div>
                        <div className="linkbuilding-website-linkplacements-body">
                            <h1>aanmaken copy object create new component</h1>
                            {/* { website.linkplacements == undefined ?
                             <div>LOADING</div>
                            :
                                <div id="linkplacements">
                                    { website.linkplacements.length == 0?
                                        <div>
                                            <p>Geen linkplacements gevonden</p>
                                        </div>
                                    :
                                    <div id="linkplacements-accordion">
                                        <Accordion multiple style={{marginTop: '10px'}}>
                                                
                                            {website.linkplacements?.map((linkplacement, index) => 
                                                <AccordionTab key={linkplacement.id || index} header={`${linkplacement.customerName} - ${linkplacement.name}}`}>
                                                    <div className="linkbuilding-website-linkplacements-use"> 
                                                        <div style={{ minWidth: '100%', maxWidth: '100%', marginRight: '5%', display: 'flex', flexDirection: 'column', paddingLeft: '5px' }}>
                                                            <div className="list-blocks">
                                                                
                                                                <div className="sub-item">
                                                                    <p className="sub-item-key">CampaignName</p>
                                                                    <p className="sub-item-value">{linkplacement.name}</p>
                                                                </div>

                                                                <div className="sub-item">
                                                                    <p className="sub-item-key">Price</p>
                                                                    <p className="sub-item-value">{linkplacement.price}</p>
                                                                </div>

                                                                <div className="sub-item">
                                                                    <p className="sub-item-key">Status</p>
                                                                    <p className="sub-item-value">{linkplacement.status}</p>
                                                                </div>

                                                                <div className="sub-item">
                                                                    <p className="sub-item-key">Publish date</p>
                                                                    <p className="sub-item-value">{linkplacement.publishDate.toDateString()}</p>
                                                                </div>                                      
                                                            </div>
                                                            <Button onClick={() => navigate(`/linkbuilding/linkplacement/${linkplacement.id}`)} label="View linkplacement" className="p-button-primary" style={{ width: '100%' }} />

                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                            )}
                                        </Accordion>
                                    </div>
                                    }
                                </div>
                            } */}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default DisplayPartnerComponent;