import { ICampaign } from '../../features/linkbuilding/models/campaign';
import {create} from 'zustand';
import { devtools, persist } from "zustand/middleware";

export interface ProjectState {
    selectedCampaign: ICampaign | undefined;

    setSelectedCampaign: (campaign: ICampaign) => void;

    reset: () => void;
}

let linkbuildingCampaignStore: any = (set): ProjectState => ({
    selectedCampaign: undefined,

    setSelectedCampaign: (campaign: ICampaign) => set((state) => ({ ...state, selectedCampaign: campaign })),

    reset: () => set({
        selectedCampaign: undefined
    }),
});


linkbuildingCampaignStore = devtools(linkbuildingCampaignStore);
linkbuildingCampaignStore = persist(linkbuildingCampaignStore, { name: 'devote_linkbuilding_campaign_store' });

const useLinkbuildingCampaignStore = create(linkbuildingCampaignStore);

export default useLinkbuildingCampaignStore;
