import React from "react";
import { ICampaign } from '../models/campaign';
import { Accordion, AccordionTab } from 'primereact/accordion';


const DisplayCampaignComponent = ( campaignDataInput:any) => {
    const [campaignData, setCampaignData] = React.useState<ICampaign>();
    React.useEffect(() => {
        setCampaignData(campaignDataInput.campaign);
    }, [campaignDataInput]);

    return (
        <div style={{ width: '100%' }}>
            <Accordion activeIndex={0}>
                <AccordionTab header={campaignData?.name}>
                    <h1 style={{marginLeft: '10px'}}>{campaignData?.name}</h1>
                    
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        
                        <div style={{ width: '50%', margin: '10px' }}>
                            <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                <p style={{ minWidth: '20%', color: '#636c79' }}>Labels</p>
                                <p style={{ marginLeft: 'auto' }}>{campaignData?.labels}</p>
                            </div>

                            <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                <p style={{ minWidth: '20%', color: '#636c79' }}>Klant url</p>
                                <p style={{ marginLeft: 'auto' }}><a href="{campaignData?.customer_DomainUrl}" target="_blank">{campaignData?.customer_DomainUrl}</a></p>
                            </div>

                            <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                <p style={{ minWidth: '20%', color: '#636c79' }}>Start datum</p>
                                <p style={{ marginLeft: 'auto' }}>{new Date(campaignData?.startDate!).toDateString()}</p>
                            </div>
                            <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                <p style={{ minWidth: '20%', color: '#636c79' }}>Eind datum</p>
                                <p style={{ marginLeft: 'auto' }}>{new Date(campaignData?.endDate!).toDateString()}</p>
                            </div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <p className="m-0">
                            {campaignData?.description}
                            </p>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>      
        </div>
    )
}

export default DisplayCampaignComponent;