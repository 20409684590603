import { ICampaign } from '../../features/linkbuilding/models/campaign';
import {create} from 'zustand';
import { devtools, persist } from "zustand/middleware";

export interface ProjectState {
    selectedCampaign: ICampaign | undefined;

    setSelectedCampaign: (campaign: ICampaign) => void;

    reset: () => void;
}

let campaignStore: any = (set): ProjectState => ({
    selectedCampaign: undefined,

    setSelectedCampaign: (campaign: ICampaign) => set((state) => ({ ...state, selectedCampaign: campaign })),

    reset: () => set({
        selectedCampaign: undefined
    }),
});


campaignStore = devtools(campaignStore);
campaignStore = persist(campaignStore, { name: 'devote_campaign_store' });

const useCampaignStore = create(campaignStore);

export default useCampaignStore;
