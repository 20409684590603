export enum WebsiteType{
    General= 1,
    Directory= 2,
    Comment= 3, 
    Artikel= 4,
    Brand= 5,
    StartPage= 6, 
    Review= 7,
    Blog= 8,    
}
export enum WebsiteStatus {
    Online= 1,
    Spammy= 2,
    Offline= 3,
    MissingLink= 4
}
export enum LinkPlacementStatus {
    Requested= 0,
    NoResponse= 1,
    Rejected= 2,
    Responded= 3,
    Agreement= 4,
    Accepted= 5,
    Reminder= 6,
}
export enum LinkPlacementCurrentStatus {
    Requested= 0,
    NoResponse= 1,
    Rejected= 2,
    Responded= 3,
    Accepted= 4,
    Planned= 5, 
    Placed= 6,
    Unknown= 7,
}
export enum WebsiteCategories {
    TechElectronica= 1,
    Financieel,
    BeautyGezondheid,
    TourismeVrijeTijd,
    Automotive,
    HuisTuin,
    EtenDrinken,
    Sport,
    Lifestyle,
    FamilieOuderschap,
    Opleidingen,
    Telecom,
    Algemeen,
    Zakelijk,
    Verzekeringen,
    Jongeren,
    LevenDood,
    CultuurReligies,
    Mannen,
    Vrouwen,
    Duurzaamheid,
    Regionaal,
    Weer,
    Senioren,
    Dieren,
    Reizen
}
