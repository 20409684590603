import React from "react";
import { IWebsite, IWebsiteLinkPlacement } from '../models/linkplacement';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import "./display-website.styles.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { Tooltip } from 'primereact/tooltip';
import { useProjectStore } from "../../../store";
import { IProject } from "../../../interfaces/project.interface";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { ApiResponse } from "../../../api/base/api-response";
import { PaginatedResponse } from "../../../api/base/paginated-response";
import { set } from "react-hook-form";
import { IContact, IWebsitePartnerFull } from "../models/contact";
import { Dialog } from "primereact/dialog";
import WebsiteFormDialogComponent from "./website-form-dialog.component";
import { BlinkBlur } from "react-loading-indicators";


const DisplayWebsiteComponent = ( websiteInputData:any, editModeInputData:boolean=false) => {
    
    const navigate = useNavigate();
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const linkbuildingService = new LinkbuildingService();
    
    const [website, setWebsite] = React.useState<IWebsite>({} as IWebsite);
    const [editMode, setEditMode] = React.useState<boolean>(false);

    const [editWebsiteDialog, setEditWebsiteDialog] = React.useState<boolean>(false);

    React.useEffect(() => {
        console.log(websiteInputData.website);
        if(website.domainUrl != websiteInputData.website.domainUrl){
            fetchWebsite(websiteInputData.website.id);
            fetchWebsiteLinkplacements(websiteInputData.website);
            setEditMode(editModeInputData);
        }
    }, [websiteInputData.website]);

    const closeEditWebsiteDialog = () => {
        setEditWebsiteDialog(false);
        fetchWebsite(website.id);
    }


    // API CALLS
    const fetchWebsiteLinkplacements = (fetchWebsite) => {
        linkbuildingService.getWebsiteLinkplacements(selectedProject.id, fetchWebsite.id).then((apiResponse: ApiResponse<PaginatedResponse<IWebsiteLinkPlacement[]>>) => {
            if (!apiResponse.hasError()) {
                apiResponse.getBody().then((body) => {
                    for (let i = 0; i < body.data.length; i++) {
                        body.data[i].publishDate = new Date(body.data[i].publishDate);
                    }
                    const updatedWebsite = {
                        ...fetchWebsite,
                        linkplacements: body.data,
                    };
                    setWebsite(updatedWebsite);
                    linkbuildingService.getWebsiteContacts(selectedProject.id, fetchWebsite.id).then((apiResponse: ApiResponse<PaginatedResponse<IContact[]>>) => {
                        if (!apiResponse.hasError()) {
                            apiResponse.getBody().then((body) => {
                                console.log(body.data);
                                const updatedWebsiteContacts = {
                                    ...updatedWebsite,
                                    contacts: body.data,
                                };
                                setWebsite(updatedWebsiteContacts); 
                                linkbuildingService.getWebsitePartnersInfo(selectedProject.id, fetchWebsite.id).then((apiResponse: ApiResponse<PaginatedResponse<IWebsitePartnerFull[]>>) => {
                                    if (!apiResponse.hasError()) {
                                        apiResponse.getBody().then((body) => {
                                            const updatedWebsitePartner = {
                                                ...updatedWebsiteContacts,
                                                websitePartners: body.data,
                                            };
                                            setWebsite(updatedWebsitePartner); 
                                        });
                                    }
                                });
                            });
                        }
                    });
                    
                });
            }
        }
    )};  

    const fetchWebsite = (websiteId) => {
        linkbuildingService.getWebsite(selectedProject.id, websiteId).then((apiResponse: ApiResponse<IWebsite>) => {
            if (!apiResponse.hasError()) {
                
                apiResponse.getBody().then((body) => {
                    setWebsite(body);
                }
                );
                
            }
        });
    }


    // BUTTON CLICKS
    const btnNewContactPerson = () => {
        console.log('New contact person');
    }

    const btnEditContactPerson = (id) => {   
        console.log('Edit contact person'); 
    }

    const btnNewPartner = () => {
        console.log('New partner');
    }

    const goToPartner = (partnerId) => {
        console.log('Go to partner');
    }


    return (
        <div style={{ width: '100', display: 'flex', flexDirection: 'column' }}>
            <Card className="linkbuilding-website" title={website?.domainUrl} subTitle=''>
                <div className="linkbuilding-website-row">
                    <div className="linkbuilding-website-info">
                        <div className="linkbuidling-website-info-scores" style={{ minWidth: '100%', maxWidth: '100%', marginRight: '5%', display: 'flex', flexDirection: 'column', paddingLeft: '5px', borderBottom: '1px solid rgba(0, 0, 0, .1) ' }}>                            
                            {/*  Property List  */}
                            <div style={{ borderTop: '1px solid rgba(0, 0, 0, .1)', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '50px', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                                <p>
                                    <span><i className='fa-solid fa-database' style={{ color: 'rgba(27,118,183,0.76)' }} /></span>
                                    <span style={{ marginLeft: '10px' }}>Cijfers en data</span>
                                </p>
                                { editMode ?
                                    <div>
                                        <Button onClick={() => setEditWebsiteDialog(true)}  label="Edit website" className="p-button-primary" style={{ marginLeft: 'auto' }} />
                                    </div>
                                    :
                                    <></>
                                }
                                <Button disabled onClick={() => {console.log("Open popup and show all changes")}} label="Show changes" className="p-button-primary" style={{ marginLeft: '10px' }} />
                            </div>
                            <div style={{ width: '100%' }} className="list-blocks">
                                <div className="sub-item">
                                    <p className="sub-item-key">DA</p>
                                    <p className="sub-item-value">{website?.da}/100</p>
                                </div>

                                <div className="sub-item">
                                    <p className="sub-item-key">TF</p>
                                    <p className="sub-item-value">{website?.tf}/100</p>
                                </div>

                                <div className="sub-item">
                                    <p className="sub-item-key">DR </p>
                                    <p className="sub-item-value">{website?.dr}/100</p>
                                </div>

                                <div className="sub-item">
                                    <p className="sub-item-key">Traffic (Ahrefs) </p>
                                    <p className="sub-item-value">{website?.traffic}</p>
                                </div>

                                <div className="sub-item">
                                    <p className="sub-item-key">Last price</p>
                                    <p className="sub-item-value">€{website?.price},-</p>
                                </div>
                            </div>
                            <p style={{marginBottom: '10px'}}>{website?.comments}</p>
                        </div>
                        <div className="linkbuilding-website-contact">
                            <Accordion activeIndex={0}>
                                <AccordionTab header="Contacten">
                                <Button onClick={btnNewContactPerson} label="Nieuwe contactpersoon" className="p-button-primary" style={{ marginLeft: 'auto' }} />

                                    { 
                                        website.contacts?.length == 0 
                                    ? 
                                        <h3>No contacts found</h3>
                                    :
                                        <>{ website.contacts?.map(function(contact, index){
                                            return (
                                                <div key={index} className="linkbuilding-website-contact-item">
                                                    <div style={{ minWidth: '100%', maxWidth: '100%', marginRight: '5%', display: 'flex', flexDirection: 'column', paddingLeft: '5px' }}>
                                                        <div className="list-blocks">
                                                            <div className="sub-item">
                                                                <p className="sub-item-key">Naam</p>
                                                                <p className="sub-item-value">{contact.name}</p>
                                                            </div>

                                                            <div className="sub-item">
                                                                <p className="sub-item-key">Email</p>
                                                                <p className="sub-item-value">{contact.email}</p>
                                                            </div>

                                                            <div className="sub-item">
                                                                <p className="sub-item-key">Telefoon</p>
                                                                <p className="sub-item-value">{contact.phone}</p>
                                                            </div>
                                                        </div>
                                                        <Button onClick={() => btnEditContactPerson(contact.id)} label="Edit contact" className="p-button-primary" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        </>
                                    }
                                    
                                </AccordionTab>
                                <AccordionTab header="Partners">
                                <Button onClick={btnNewPartner} label="Nieuwe partner koppeling" className="p-button-primary" style={{ marginLeft: 'auto' }} />
                                { 
                                        website.websitePartners?.length == 0 
                                    ? 
                                        <h3>No partners found</h3>
                                    :
                                        <>{ website.websitePartners?.map(function(partner, index){
                                            return (
                                                <div key={index} className="linkbuilding-website-contact-item">
                                                    <div style={{ minWidth: '100%', maxWidth: '100%', marginRight: '5%', display: 'flex', flexDirection: 'column', paddingLeft: '5px' }}>
                                                        <div className="list-blocks">
                                                            <div className="sub-item">
                                                                <p className="sub-item-key">Naam</p>
                                                                <p className="sub-item-value">{partner.name}</p>
                                                            </div>

                                                            <div className="sub-item">
                                                                <p className="sub-item-key">Price</p>
                                                                <p className="sub-item-value">{partner.price}</p>
                                                            </div>

                                                            <div className="sub-item">
                                                                <p className="sub-item-key">Comment</p>
                                                                <p className="sub-item-value">{partner.comment}</p>
                                                            </div>
                                                        </div>
                                                        <Button onClick={() => goToPartner(partner.partnerId)} label="Go to partner" className="p-button-primary" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        </>
                                    }
                                </AccordionTab>
                            </Accordion> 
                        </div>
                    </div>
                    <div className="linkbuilding-website-linkplacements">
                        <div className="linkbuilding-website-linkplacements-header">
                            <div style={{ borderTop: '1px solid rgba(0, 0, 0, .1)', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '50px', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                                <p>
                                    <span><i className='fa-solid fa-link' style={{ color: 'rgba(27,118,183,0.76)' }} /></span>
                                    <span style={{ marginLeft: '10px' }} className="recent-linkplacement">Recente linkplacements </span>
                                    <Tooltip 
                                        content="Alle linkplacements bij dezelfde klant, in de afgelopen 2 jaar & alle linkplacements afgelopen jaar" 
                                        position="top" 
                                        style={{marginLeft: '10px'}}
                                        target=".recent-linkplacement">
                                    </Tooltip>
                                </p>
                            </div>                            
                        </div>
                        <div className="linkbuilding-website-linkplacements-body">
                            { website.linkplacements == undefined ?
                             <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
                            :
                                <div id="linkplacements">
                                    { website.linkplacements.length == 0?
                                        <div>
                                            <p>Geen linkplacements gevonden</p>
                                        </div>
                                    :
                                    <div id="linkplacements-accordion">
                                        <Accordion multiple style={{marginTop: '10px'}}>
                                                
                                            {website.linkplacements?.map((linkplacement, index) => 
                                                <AccordionTab key={linkplacement.id || index} header={`${linkplacement.customerName} - ${linkplacement.name}}`}>
                                                    <div className="linkbuilding-website-linkplacements-use"> 
                                                        <div style={{ minWidth: '100%', maxWidth: '100%', marginRight: '5%', display: 'flex', flexDirection: 'column', paddingLeft: '5px' }}>
                                                            {/*  Property List  */}
                                                            <div className="list-blocks">
                                                                
                                                                <div className="sub-item">
                                                                    <p className="sub-item-key">CampaignName</p>
                                                                    <p className="sub-item-value">{linkplacement.name}</p>
                                                                </div>

                                                                <div className="sub-item">
                                                                    <p className="sub-item-key">Price</p>
                                                                    <p className="sub-item-value">{linkplacement.price}</p>
                                                                </div>

                                                                <div className="sub-item">
                                                                    <p className="sub-item-key">Status</p>
                                                                    <p className="sub-item-value">{linkplacement.status}</p>
                                                                </div>

                                                                <div className="sub-item">
                                                                    <p className="sub-item-key">Publish date</p>
                                                                    <p className="sub-item-value">{linkplacement.publishDate.toDateString()}</p>
                                                                </div>                                      
                                                            </div>
                                                            <Button onClick={() => navigate(`/linkbuilding/linkplacement/${linkplacement.id}`)} label="View linkplacement" className="p-button-primary" style={{ width: '100%' }} />

                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                            )}
                                        </Accordion>
                                    </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Card>

            <Dialog header='Edit website' visible={editWebsiteDialog} onHide={() => { setEditWebsiteDialog(false) }}>
                <WebsiteFormDialogComponent closeDialog={closeEditWebsiteDialog} website={website} />
            </Dialog>
        </div>
    )
}

export default DisplayWebsiteComponent;