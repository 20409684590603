/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React, { useEffect } from "react";
import ApplicationCard from "../../../../components/application-card/application-card.component";
import "./homepage.styles.scss"


const Homepage = () => {
    return (
        <>
            {/* Header is here */}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100%', minHeight: '250px', maxHeight: '250px', backgroundColor: 'rgb(79, 84, 97)' }}>
                <h1 style={{ color: '#fff' }}>Welcome to Springbok Devote.</h1>
                <h2 style={{ color: '#fff', fontSize: '14px' }}>Use one of the cards below to use our applications!</h2>
            </div>

            {/* Cards here */}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '80px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '15px', justifyContent: 'flex-start' }}>
                    <ApplicationCard link={'/springbok-gpt'} title={'Springbok GPT'} icon={'fa-solid fa-comment-dots'} iconColor={'#8187FF'} description={"Retrieve insights in your data using a smart AI-based chat tool"} />
                    <ApplicationCard link={'/connectors'} title={'Connectors'} icon={'pi pi-sitemap'} iconColor={'#F08418'} description={"Connect with your data sources and build data pipelines to your data storage"} />
                    <ApplicationCard link={'/advantiv'} title={'Advantiv'} icon={'fa-solid fa-arrows-to-eye'} iconColor={'#15A18A'} description={'Plan your marketing campaigns and calculate optimal spend across channels'} />
                    <ApplicationCard link={'/cloudformer'} title={'CloudFormer'} icon={'pi pi-cloud'} iconColor={'#DDFF00'} description={"Get Springbok solutions in terraform, ready to be applied in the environment you want!"} />
                    <ApplicationCard link={'/linkbuilding'} title={'Linkbuilding'} icon={'pi pi-link'} iconColor={'#B9A574'} description={"Linkbuilding, register everything right"} />
                </div>
            </div>
        </>
    );
};

export default Homepage


