import React from "react";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { IChangeLog } from "../models/changelog";
import { DataTable, DataTableExpandedRows } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

const DisplayChangeLogItems = ({ objectId }) => {
    React.useEffect(() => {}, []);

    // SERVICES
    const linkbuildingService = new LinkbuildingService();

    // STATES
    const [logs, setLogs] = React.useState<IChangeLog[]>([]);
    const [expandedRows, setExpandedRows] = React.useState<DataTableExpandedRows>({});

    // CLICKS
    const onTabOpen = (e) => {
        if (logs.length === 0) {
            linkbuildingService.getChangeLogs("", objectId).then((response) => {
                response.getBody().then((body) => {
                    let items: IChangeLog[] = [];
                    for (let i = 0; i < body.data.length; i++) {
                        let subItem = body.data[i];
                        subItem.object_display = JSON.parse(subItem.object);
                        items.push(subItem as IChangeLog);
                    }
                    setLogs(items);
                });
            });
        }
    };

    const expandAll = () => {
        const _expandedLogs: DataTableExpandedRows = {};
        logs.forEach((log) => {
            _expandedLogs[`${log.id}`] = true;
        });
        setExpandedRows(_expandedLogs);
    };

    const collapseAll = () => {
        setExpandedRows({});
    };

    const ChangeDateBodyTemplate = (rowData: IChangeLog) => {
        return new Date(rowData.changeDate).toLocaleString(); // Use `new Date` for proper formatting
    };

    const rowExpansionTemplate = (data: IChangeLog) => {
        console.log(data);
        return (
            <div className="p-3">
                {
                    Object.keys(data.object_display).map((element) => (
                        <div key={element} className="mb-3">
                            <p><strong>{element}</strong> <span>{data.object_display[element]}</span></p>
                        </div>
                    ))
                }
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap justify-content-end gap-2">
            <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text />
            <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} text />
        </div>
    );

    return (
        <Card title="Change Log" subTitle="View the change log for this object">
            <div>
                <Accordion onTabOpen={onTabOpen}>
                    <AccordionTab header="Logs">
                        <p className="m-0">
                            <DataTable
                                value={logs}
                                expandedRows={expandedRows}
                                onRowToggle={(e) =>
                                    setExpandedRows(e.data as DataTableExpandedRows)
                                } // Cast e.data to DataTableExpandedRows
                                rowExpansionTemplate={rowExpansionTemplate}
                                dataKey="id"
                                header={header}
                                tableStyle={{ minWidth: "60rem" }}
                            >
                                <Column expander={true} style={{ width: "5rem" }} />
                                <Column field="item" header="Item" sortable />
                                <Column field="action" header="Action" sortable />
                                <Column field="userName" header="User" sortable />
                                <Column
                                    field="changeDate"
                                    header="Date"
                                    sortable
                                    body={ChangeDateBodyTemplate}
                                />
                            </DataTable>
                        </p>
                    </AccordionTab>
                </Accordion>
            </div>
        </Card>
    );
};

export default DisplayChangeLogItems;
