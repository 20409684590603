
import Navbar from "../../../../components/navbar/navbar.component";

const LinkbuildingHome = () => {
    return (
        <>
            <Navbar activeTool={'Linkbuilding'} activePage={'Home'} />
            <div>
                <h1>Linkbuilding Home</h1>
                <p>
                    Alle linkplacements van projecten die open staat op jouw projecten en aagemaakt zijn door jou
                </p>
            </div>
        </>
    );
}
export default LinkbuildingHome;