/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


import React from 'react';
import { useNavigate } from "react-router-dom";
import { Messages } from "primereact/messages";
import { Button } from 'primereact/button';
import { Disclaimer } from "../../components/disclaimer/disclaimer.components";
import { IdentityService } from "../../../../api/identity-service";
import { useAuthStore } from "../../../../store";
import './login.styles.scss';


const LoginComponent = () => {

    const navigate = useNavigate();
    const authStore = useAuthStore();
    const messagesRef = React.useRef<Messages>(null);

    const [loggingIn, setLoggingIn] = React.useState<boolean>(false);

    const [username, setUsername] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoggingIn(true);

        new IdentityService().login(username, password).then((response: Response) => {
            if (!response.ok) {
                setLoggingIn(false);
                showLoginError();
                return;
            }

            response.json().then((data) => {
                setLoggingIn(false);
                authStore.login(username, data.access_token, data.refresh_token);
                navigate('/home');
            });
        });
    }
    
    const showLoginError = () => {
        messagesRef.current?.replace([{
            severity: 'error',
            detail: 'Failed to login, please check your email/password or contact your administrator.',
            sticky: true
        }]);
    }

    return (
        <div className='login-container'>

            <h1 className="title">
                <small>Welcome to</small>
                <br />
                <span>Springbok Devote</span>
            </h1>

            <form className='login-form' onSubmit={(e) => submit(e)}>
                <span className='login-form-field p-input-icon-left'>
                    <input className='login-form-field-input' type="text" placeholder="Email" onChange={(e) => setUsername(e.target.value)} />
                </span>
                <span className='login-form-field p-input-icon-left'>
                    <input className='login-form-field-input' type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                </span>

                <Button className='login-form-submit-button' label='Login' type="submit" loading={loggingIn} />
            </form>

            <Messages className="login-form-error" ref={messagesRef} />
            <Disclaimer />
        </div>
    );
}

export default LoginComponent;
