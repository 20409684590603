import React, { useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import Navbar from "../../../../components/navbar/navbar.component";
import { LinkbuildingService } from "../../../../api/linkbuilding-service";
import { set } from "react-hook-form";
import { ApiResponse } from "../../../../api/base/api-response";
import { PaginatedResponse } from "../../../../api/base/paginated-response";
import { ILinkPlacement, IPartner, IWebsite, IWebsiteLinkPlacement } from "../../models/linkplacement";
import { DataTable } from "primereact/datatable";
import DisplayWebsiteComponent from "../../components/display-website.component";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { useParams, useSearchParams } from "react-router-dom";
import { useProjectStore } from "../../../../store";
import { IProject } from "../../../../interfaces/project.interface";
import { Dialog } from "primereact/dialog";
import CreateCampaignDialogComponent from "../../components/create-campaign-dialog.component";
import WebsiteFormDialogComponent from "../../components/website-form-dialog.component";
import { ConfirmDialog } from "primereact/confirmdialog";
import CreatePartnerDialogComponent from "../../components/create-partner-dialog.component";
import DisplayPartnerComponent from "../../components/display-partner.component";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { InputNumber } from "primereact/inputnumber";
import { FilterMatchMode } from 'primereact/api';
import { Slider } from 'primereact/slider';
import WebsiteListSelectie from "../../components/websiteListSelectie";
import { BlinkBlur } from "react-loading-indicators";


const LinkbuildingWebsites = () => {

    // Services
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const linkbuildingService = new LinkbuildingService();
    const [searchParams, setSearchParams] = useSearchParams();


    // Button actions
    const loadPanelData = async (indexEvent) => {
        if (indexEvent.index === 0) {
            activateWebsiteTab();
        } else {
            activatePartnersTab();
        }
    }
    const activateWebsiteTab = async (id?) => {
        console.log('activateWebsiteTab');
        setActiveIndex(0);
    }
    const activatePartnersTab = async () => {
        setActiveIndex(1);
        await fetchPartners();
    }
    const changeDisplayWebsite = (e) => {
        console.log(e)
        // TODO check if e.value is null
        // TODO check website has already full sub data other wise load specific website data
        if(e.value != null) {
            loadSelectedWebsite(e.value);
        }
    }
    const loadSelectedWebsite = (website) => {
        if(website != undefined && website.id !== null && website.id != undefined && website.id != selectedWebsite.id) {
            console.log(website);
            setSelectedWebsite(website);
            // fetchWebsiteLinkplacement(website);
        }
    }

    const changeDisplayPartner = (e) => {
        // TODO check if e.value is null
        // TODO check website has already full sub data other wise load specific website data
        if(e.value != null) {
            loadSelectedPartner(e.value);
        }
    }
    const loadSelectedPartner = (partner) => {
        if(partner != undefined && partner.id !== null && partner.id != undefined && partner.id != selectedPartner.id) {
            console.log(partner);
            setSelectedPartner(partner);
            // fetchWebsiteLinkplacement(website);
        }
    }


    // List of API data
    const [partners, setPartners] = React.useState<IPartner[]>([]);

    // States
    const [activeIndex, setActiveIndex] = React.useState(-1);
    const [partnerLoading, setPartnerLoading] = React.useState(false);
    const [selectedWebsite, setSelectedWebsite] = React.useState<IWebsite>({} as IWebsite);
    const [selectedPartner, setSelectedPartner] = React.useState<IPartner>({} as IPartner);
    const [newPartnerDialog, setNewPartnerDialog] = React.useState(false);

    // Displays datatable
    
    const partnerHeader = (
        <>
            <div className="table-header">
                <h5 className="mx-0 my-1">Partners</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                </span>
                <Button 
                    icon="pi pi-plus" 
                    className="p-button p-button-success mr-2" 
                    onClick={() => setNewPartnerDialog(true)} 
                    value="Create a new partner" />
            </div>  
        </>
    )

    // API requests

    const fetchPartners = async () => {
        setPartnerLoading(true);
        linkbuildingService.getPartners().then((apiResponse: ApiResponse<PaginatedResponse<IPartner[]>>) => {
            if (!apiResponse.hasError()) {
                apiResponse.getBody().then((body: PaginatedResponse<IPartner[]>) => {
                    setPartners(body.data);
                    setPartnerLoading(false);
                });
            }
        });
    }

    React.useEffect(() => {
        const fetchData = async () => {
            if (searchParams.get('website') != null) {
                // await setSelectedWebsite({ id: searchParams.get('website') } as IWebsite);
                // console.log(selectedWebsite)
                // await activateWebsiteTab(searchParams.get('website'));
                await activateWebsiteTab();
                // console.log(websites)

                // loadSelectedWebsite({ id: searchParams.get('website') } as IWebsite);
                
            }
            else if (searchParams.get('partner') != null) {
                activatePartnersTab();
            }
            else if (activeIndex === -1) {
                activateWebsiteTab();
            }
        };
        fetchData();
    }, []);

    

    return (
        <>
            <div>
                <Navbar activeTool={'Linkbuilding'} activePage={'Websites'} />
                <div className="layout-application-window" style={{ padding: '0px 40px' }} >
                    <TabView onTabChange={(e) => loadPanelData(e)} activeIndex={activeIndex}>
                        <TabPanel header="Websites">
                            <div>
                                { selectedWebsite.id != undefined ?
                                    <div style={{marginBottom:'10px'}}>
                                    <DisplayWebsiteComponent website={selectedWebsite} style={{marginTop:'10px'}} />
                                    </div>
                                : 
                                null }
                                    
                                <WebsiteListSelectie setWebsiteSelected={changeDisplayWebsite} rowsPerPageOptionInput={[15, 30, 50]}/>
                            </div>
                        </TabPanel>
                        <TabPanel header="Partners">
                        <div>
                                { selectedPartner.id != undefined ?
                                    <div style={{marginBottom:'10px'}}>
                                    <DisplayPartnerComponent partner={selectedPartner} style={{marginTop:'10px'}} />
                                    </div>
                                    : null }
                                { partnerLoading && partners.length === 0 ?
                                <div>
                                    <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
                                </div>
                                :
                                <div>
                                    { partnerLoading ?
                                    <div>
                                        <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
                                    </div>
                                    : null }
                                    <DataTable 
                                        value={partners} 
                                        paginator 
                                        rows={10} 
                                        rowsPerPageOptions={[5, 10, 20]}
                                        emptyMessage="No partners found"
                                        selection={selectedPartner}
                                        onSelectionChange={(e) => changeDisplayPartner(e)}
                                        header={partnerHeader}
                                        dataKey="id"
                                        selectionMode="single" 
                                    >
                                        <Column field="name" header="Name" sortable />
                                        <Column field="comment" header="Comments" sortable />
                                        <Column field="amountSites" header="Amount sites" sortable />
                                    </DataTable>
                                </div>
                                }
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
            
            <Dialog header='Create new partner' visible={newPartnerDialog} onHide={() => { setNewPartnerDialog(false) }}>
                <CreatePartnerDialogComponent setDialogVisible={newPartnerDialog} />
            </Dialog>
            {/* This confirm-dialog is empty by intent, it's filled by the confirm function. */}
            <ConfirmDialog />
        </>
    )
}

export default LinkbuildingWebsites;