import React from "react";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { useProjectStore } from "../../../store";
import { IProject } from "../../../interfaces/project.interface";
import { ApiResponse } from "../../../api/base/api-response";
import { ICampaign } from "../models/campaign";
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";


const CreateCampaignDialogComponent = ({ setDialogVisible }) => {
    
    React.useEffect(() => {

    }, []);
    
    // STORES.
    const project: IProject = useProjectStore(state => state.selectedProject)

    
    // FORM FIELDS
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
    const [campaignName, setCampaignName] = React.useState<string>('');
    const [campaignDescription, setCampaignDescription] = React.useState<string>('');
    const [campaignStartDate, setCampaignStartDate] = React.useState<Date>(new Date());
    const [campaignEndDate, setCampaignEndDate] = React.useState<Date>(new Date());
    const [creatorName, setCreatorName] = React.useState<string>('');

    // BUTTON ACCTIONS
    const submitCreateNewCampaignForm = () => {
        // setFormIsSubmitting(true);

        const linkbuildingService = new LinkbuildingService();
        linkbuildingService.createCampaign(project.id, {
            name: campaignName,
            description: campaignDescription,
            startDate: campaignStartDate,
            endDate: campaignEndDate,
            labels: `nieuw,actief,${creatorName}`
        }).then((apiResponse: ApiResponse<ICampaign>) => {
            if (apiResponse.hasError()) {
                // Todo: implement error handling.
            } else {
                location.reload();
            }
        })
    }

    return (
        <div style={{ width: '350px' }}>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Naam:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
            </div>

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Omschrijving:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={campaignDescription} onChange={(e) => setCampaignDescription(e.target.value)} />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Start datum:</p>
                <Calendar
                    style={{ width: '100%' }}
                    value={new Date(campaignStartDate)}
                    onChange={(e) => {setCampaignStartDate(e.target.value as Date )}}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Eind datum:</p>
                <Calendar
                    style={{ width: '100%' }}
                    value={new Date(campaignEndDate)}
                    onChange={(e) => {setCampaignEndDate(e.target.value as Date )}}
                />
            </div>

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Gebruikersnaam:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={creatorName} onChange={(e) => setCreatorName(e.target.value)} />
            </div>


            <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => setDialogVisible(false)}/>

                {
                    formIsSubmitting ?
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) :
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submitCreateNewCampaignForm()} />)
                }
                
             </div>
        </div>
    )
}

export default CreateCampaignDialogComponent;