
import React from "react";
import Navbar from "../../../../components/navbar/navbar.component";
import { LinkbuildingService } from "../../../../api/linkbuilding-service";
import { ICampaign, ICampaignBudgetOverview, ICampaignLinkplacement, ICampaignPageLink } from "../../models/campaign";
import { useProjectStore, useLinkbuildingCampaignStore } from "../../../../store";
import { IProject } from "../../../../interfaces/project.interface";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Column } from "primereact/column";
import { PaginatedResponse } from "../../../../api/base/paginated-response";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { Tooltip } from "primereact/tooltip";
import "./linkbuilding-campaigns.styles.scss";
import { link } from "fs";
import { BlinkBlur } from "react-loading-indicators";
import MessageDialogComponent from "../../components/message-dialog.component";
import CreateCampaignDialogComponent from "../../components/create-campaign-dialog.component";
import { useNavigate } from "react-router";
import EditCampaignDialogComponent from "../../components/edit-campaign-dialog.component";
import { numberEditor, textEditor } from "../../components/data-form-helpers";
import DisplayChangeLogItems from "../../components/changeLogDisplay";
import { ApiResponse } from "../../../../api/base/api-response";
import BudgetChart from "../../components/budget-chart";

const LinkbuildingCampaign = () => {

    const linkbuildingService = new LinkbuildingService();
    const navigate = useNavigate();

    // Data stores
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const campaignStore = useLinkbuildingCampaignStore();
    
    // State    
    const [newCampaignDialog, setNewCampaignDialog] = React.useState(false);
    const [editCampaignDialog, setEditCampaignDialog] = React.useState(false);
    const [messageDialog, setMessageDialog] = React.useState(false);
    const [messageDialogContent, setMessageDialogContent] = React.useState<{ title?: string, message?: string, type?: string }>({});
    const [subLoadingDataBudget, setSubLoadingDataBudget] = React.useState(false);
    const [campaignBudgets, setCampaignBudgets] = React.useState<ICampaignBudgetOverview[]>([]);
    const [subLoadingDataLinkplacements, setSubLoadingDataLinkplacements] = React.useState(false);
    const [subLoadingDataKeywords, setSubLoadingDataKeywords] = React.useState(false);
    const [devote_user_name, set_devote_user_name] = React.useState(localStorage.getItem('devote_user_name') ?? 'Not known');
    const [filters, setFilters] = React.useState({
        labels: { value: '', matchMode: 'contains' as 'contains' },
    });

    // List of api data
    const [campaigns, setCampaigns] = React.useState<ICampaign[]>([]);
    

    React.useEffect(() => {
    
        setFilters({ labels: { value: devote_user_name, matchMode: 'contains' } });
        console.log("useeffect linkbuilding-campaign")
        try {
            console.log("Test 3");
            if(campaigns.length == 0){
                collectCampaigns();
            }
            console.log("Selected campaign", campaignStore.selectedCampaign);
            if(campaignStore.selectedCampaign?.id != null){
                collectFullCampaignInfo();
            }
        }
        catch (error) {
            console.error(error);
            setMessageDialogContent({
                title: "Oeps er ging iets mis",
                message: "Er is iets misgegaan bij het ophalen van de campagne, het kan zijn dat je verouderde data ziet.",
                type: "error"
            });
            setMessageDialog(true);
        }
    }, [campaigns, campaignStore.selectedCampaign]);

    // BUTTON ACTIONS
    const btnClickOpenNewCampaign = () => {
        setNewCampaignDialog(true);
    }
    const btnClickOpenEditCampaign = () => {
        setEditCampaignDialog(true);
    }
    const btnResetSelectedCampaign = () => {
        campaignStore.reset();
    }
    const btnClickOpenNewCampaignPageLink = () => {
        linkbuildingService.createCampaignPageLink(selectedProject.id, campaignStore.selectedCampaign.id, {
            allocation: 0,
            pageLinkText: "Please update",
            campaignId: campaignStore.selectedCampaign.id,
        }).then((response) => {
            if (!response.hasError()) {
                response.getBody().then((response) => {
                    let campaign = campaignStore.selectedCampaign;
                    campaign.campaignPageLinks.push(response);
                    campaignStore.setSelectedCampaign(campaign);
                })
            }
        });
    }
    const btnClickOpenNewLinkplacement = () => {
        navigate("/linkbuilding/linkplacement/new");
    }
    const btnClickOpenExistingLinkplacement = (linkplacementId) => {
        navigate(`/linkbuilding/linkplacement/${linkplacementId}`);
    }

    const btnUpdateCampaign = (campaignId) => {
        const camapign = campaigns.filter(c => c.id == campaignId)[0];
        campaignStore.setSelectedCampaign(camapign)
    }
    const updateCampaignByLabels = (labels) => {
        linkbuildingService.updateCampaign(selectedProject.id, {
            id: campaignStore.selectedCampaign.id,
            name: campaignStore.selectedCampaign.name,
            description: campaignStore.selectedCampaign.description,
            startDate: campaignStore.selectedCampaign.startDate,
            endDate: campaignStore.selectedCampaign.endDate,
            labels: labels,
        }).then((apiResponse: ApiResponse<ICampaign>) => {
            if (apiResponse.hasError()) {
                // Todo: implement error handling.
            } else {
                apiResponse.getBody().then((data) => {
                    campaignStore.setSelectedCampaign(data);
                    location.reload();
                });
            }
        })
    }

    const removeFromCampaign = () => {  
        let labels = campaignStore.selectedCampaign.labels.split(",");
        labels = labels.filter(e => e != devote_user_name);
        let campaign = campaignStore.selectedCampaign;
        campaign.labels = labels.join(",");
        updateCampaignByLabels(campaign.labels);

    }
    const addToCampaign = () => {
        let labels = campaignStore.selectedCampaign.labels.split(",");
        labels.push(devote_user_name);
        let campaign = campaignStore.selectedCampaign;
        campaign.labels = labels.join(",");
        updateCampaignByLabels(campaign.labels);
    }

    // API REQUESTS
    const collectCampaigns = () => {
        linkbuildingService.getLinkbuildingCampaigns(selectedProject.id).then((response) => {
            if (!response.hasError()) {
                response.getBody().then((paginatedResponse: PaginatedResponse<ICampaign[]>) => {
                    let items = paginatedResponse.data;
                    console.log(items);
                    for (let i = 0; i < items.length; i++) {
                        items[i].startDate = new Date(items[i].startDate);
                        items[i].endDate = new Date(items[i].endDate);
                    }
                    setCampaigns(items);
                    console.log(items);
                })
            }
            else {
                setMessageDialogContent({
                    title: "Oeps er ging iets mis",
                    message: "Er is iets misgegaan bij het ophalen van de campagne, het kan zijn dat je verouderde data ziet.",
                    type: "error"
                });
                console.error(response);
                setMessageDialog(true);
            }
        });
    };
    const collectFullCampaignInfo = () => {
        if(campaignStore.selectedCampaign.id != null){
            setSubLoadingDataLinkplacements(true);
            setSubLoadingDataKeywords(true);
            setSubLoadingDataBudget(true); 
               
            
            __refreshCampaignPageLinks();
            __refreshCampaignLinkPlacements();
            __refreshCampaignBudgets();
            
        }
    };
    const updateCampaignPageLink = (rowEdit) => {
        const campaignPageLink = rowEdit.newData
        linkbuildingService.updateCampaignPageLink(selectedProject.id, campaignStore.selectedCampaign.id, campaignPageLink).then((responsePageLink) => {
            if (!responsePageLink.hasError()) {
                responsePageLink.getBody().then((responsePageLinkBody) => {
                    let campaign = campaignStore.selectedCampaign;
                    campaign.campaignPageLinks = campaign.campaignPageLinks.filter(e => e.id != responsePageLinkBody.id);
                    console.log(campaign);
                    campaign.campaignPageLinks.push(responsePageLinkBody);
                    campaignStore.setSelectedCampaign(campaign);
                })
            }
        });
    }
    const __refreshCampaignPageLinks = () => {
        console.log("Refreshing campaign page links");
        setSubLoadingDataKeywords(true);
        linkbuildingService.getCampaignPageLinks(selectedProject.id, campaignStore.selectedCampaign.id).then((response) => {
            if (!response.hasError()) {
                response.getBody().then((paginatedResponse) => {
                    console.log(campaignStore.selectedCampaign);
                    let campaign = campaignStore.selectedCampaign;
                    campaign.campaignPageLinks = paginatedResponse.data;
                    campaignStore.setSelectedCampaign(campaign);
                    setSubLoadingDataKeywords(false);
                })
            }
            else {
                setMessageDialogContent({
                    title: "Oeps er ging iets mis",
                    message: "Er is iets misgegaan bij het ophalen van de campagne, het kan zijn dat je verouderde data ziet.",
                    type: "error"
                });
                setMessageDialog(true);
            }
        });
    }
    const __refreshCampaignLinkPlacements = () => {
        console.log("Refreshing campaign linkplacements");
        linkbuildingService.getCampaignLinkplacements(selectedProject.id, campaignStore.selectedCampaign.id).then((response) => {
            if (!response.hasError()) {
                response.getBody().then((paginatedResponse) => {
                    let campaign = campaignStore.selectedCampaign;
                    campaign.linkplacements = [];
                    console.log("Linkplacements", paginatedResponse.data);
                    for(let i = 0; i < paginatedResponse.data.length; i++){
                        let item = paginatedResponse.data[i];
                        item.publishDate = new Date(item.publishDate);
                        item.lastUpdate = new Date(item.lastUpdate);
                        campaign.linkplacements.push(item);
                    }
                    campaignStore.setSelectedCampaign(campaign);
                    setSubLoadingDataLinkplacements(false);
                })
            }
            else {
                setMessageDialogContent({
                    title: "Oeps er ging iets mis",
                    message: "Er is iets misgegaan bij het ophalen van de campagne, het kan zijn dat je verouderde data ziet.",
                    type: "error"
                });
                setMessageDialog(true);
            }
        });
    }

    const __refreshCampaignBudgets = () => {
        setSubLoadingDataBudget(true);
        linkbuildingService.getCampaignBudgetsOverview(selectedProject.id, campaignStore.selectedCampaign.id).then((response) => {
            response.getBody().then((paginatedResponse) => {
                let items = paginatedResponse.data;
                console.log(items);
                setCampaignBudgets(items);
                setSubLoadingDataBudget(false);
            });
        });   
    }


    // FEATURES HEADERS
    const campaignOverviewHeader = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Campaigns</h5>
            <Button 
                icon="pi pi-plus" 
                className="p-button p-button-success mr-2" 
                onClick={() => btnClickOpenNewCampaign()} 
                value="Create a new project" />
        </div>
    )
    const campaignPageLinksHeader = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Campaign Page Links</h5>
            <Button 
                icon="pi pi-plus" 
                className="p-button p-button-success mr-2" 
                onClick={() => btnClickOpenNewCampaignPageLink()}
                value="Create a new campaign page link" />
        </div>
    )
    const campaignLinkplacementHeader = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Campaign Linkplacements</h5>
            <Button 
                icon="pi pi-plus" 
                className="p-button p-button-success mr-2" 
                onClick={() => btnClickOpenNewLinkplacement()}
                value="Create a new linkplacement" />
        </div>
    )
    // FEATURES DATA TABLES 
    const campaignsActionBodyTemplate = (rowData: any) => {
        return (
            <div className="d-flex justify-content-center">
                <Button 
                    icon="pi pi-eye" 
                    className="p-button-rounded p-button-success p-mr-2" 
                    onClick={() => { btnUpdateCampaign(rowData.id) }} />
                {/* <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-danger" 
                    onClick={() => { linkbuildingCampaignStore.confirmDeleteCampaign(rowData.id) }} /> */}
            </div>
        )
    }
    const campaignPageLinksActionBodyTemplate = (rowData: ICampaignPageLink) => {
        return (
            <div className="d-flex justify-content-center">
                <Button 
                    icon="pi pi-eye" 
                    className="p-button-rounded p-button-success p-mr-2" 
                    onClick={() => console.error(`Please update filter linkplacements pagelink to ${rowData.pageLinkText}`) } />
                { rowData.keywordCount == 0 || rowData.keywordCount == null ?
                    <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-danger" 
                    onClick={() => { linkbuildingService.deleteCampaignPageLink(selectedProject.id, campaignStore.selectedCampaign.id, rowData.id) }} />
                    : null
                }
            </div>
        )
    }
    const campaignLinkplacementBodyTemplate = (rowData: ICampaignLinkplacement) => {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Button 
                    icon="pi pi-eye" 
                    className="p-button-rounded p-button-success p-mr-2" 
                    onClick={() => btnClickOpenExistingLinkplacement(rowData.id) } />
                </div>
            </>
        )
    }
    const displayDateBodyTemplate = (rowData: any, key: string) => {
        if(rowData[key] != null && rowData[key] != undefined){
            if(rowData[key] instanceof Date){
                return rowData[key].toDateString();
            }else
            {
                return new Date(rowData[key]).toDateString();
            }
        }
        return "";
    }
    
    const dataCampaignPageLinkTextEditor = (options) => {
        if(options.rowData.allocation == 0 || options.rowData.allocation == null){
            return textEditor(options);
        }
        return <span>{options.value}</span>
    }

    return (
        <>
            <Navbar activeTool={'Linkbuilding'} activePage={'Campaign'} />
            { campaignStore.selectedCampaign == null?
                <div className="layout-application-window" style={{ padding: '0px 40px' }} >
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '25px' }}>
                        <h1 style={{ margin: '50px 0 0 0', padding: '0', fontWeight: '500', color: '#000' }}>Explore your campaigns</h1>
                        <h5 style={{ margin: '5px 0 0 0', padding: '0', fontWeight: '400', color: '#525252' }}>Select a campaign from the list below to view it.</h5>
                    </div>
                        <DataTable value={campaigns}
                            header={campaignOverviewHeader}
                            className="p-datatable-sm p-datatable-gridlines"
                            showGridlines={true}
                            stripedRows={true}
                            filters={filters}
                        >
                            <Column field="name" header="Naam" sortable filter/>
                            <Column field="labels" header="Labels" sortable filter/>
                            <Column header="Start datum" body={(row) => displayDateBodyTemplate(row, 'startDate')}sortable />
                            <Column header="Eind datum" body={(row) => displayDateBodyTemplate(row, 'endDate')}sortable />
                            <Column header="" body={campaignsActionBodyTemplate}/>
                            {/* <Column field="actions" header="Actions" body={actionBodyTemplate}></Column> */}
                        </DataTable>
                </div>                          
            : 
                <div className="layout-application-window" style={{ padding: '0px 40px' }} >
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '25px' }}>
                        <h1 style={{ margin: '50px 0 0 0', padding: '0', fontWeight: '500', color: '#000' }}>Explore your campaign `{campaignStore.selectedCampaign.name}`</h1>
                        {/* <h5 style={{ margin: '5px 0 0 0', padding: '0', fontWeight: '400', color: '#525252' }}>{campaignStore.selectedCampaign.description}</h5> */}
                    </div>

                    {/* These are the 2 cards at the top.  */}
                    <div className="linkbuilding-overview-row">
                        <Card className="linkbuilding-overview-general" title="Campaign Overview" subTitle='View your campaign details'>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ minWidth: '100%', maxWidth: '100%', marginRight: '5%', display: 'flex', flexDirection: 'column', paddingLeft: '5px' }}>

                                    {/*  Header (With icon + name)  */}
                                    <div style={{ borderTop: '1px solid rgba(0, 0, 0, .1)', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '50px', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                                        <p>
                                            <span><i className='fa-solid fa-bullseye' style={{ color: 'rgba(27,118,183,0.76)' }} /></span>
                                            <span style={{ marginLeft: '10px' }}>{campaignStore.selectedCampaign.name}</span>
                                        </p>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Tooltip target=".edit-campaign" content='Click here to edit the campaign' position="top" />

                                            <i onClick={() => btnClickOpenEditCampaign()} className="fa-regular fa-pen-to-square edit-campaign" style={{ cursor: 'pointer', marginLeft: '10px', color: 'rgba(27,118,183,0.76)' }} />
                                        </div>
                                    </div>

                                    {/* Campaign Description */}
                                    <div style={{ padding: '20px 0', borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                                        <p style={{ color: '#636c79' }}>
                                            {campaignStore.selectedCampaign.description ?? "                                        " +
                                                "Here is a description about the campaign, why it is created and what it is used for.\n" +
                                                "You can use this space to write a description about the campaign. This is some filler " +
                                                "content to show how the description will look like."}

                                        </p>
                                    </div>

                                    {/*  Property List  */}
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                            <p style={{ minWidth: '20%', color: '#636c79' }}>Labels</p>
                                            <p style={{ marginLeft: 'auto' }}>{campaignStore.selectedCampaign.labels}</p>
                                        </div>

                                        <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                            <p style={{ minWidth: '20%', color: '#636c79' }}>Klant url</p>
                                            <p style={{ marginLeft: 'auto' }}><a href="{campaignStore.selectedCampaign.customer_DomainUrl}" target="_blank">{campaignStore.selectedCampaign.customer_DomainUrl}</a></p>
                                        </div>

                                        <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                            <p style={{ minWidth: '20%', color: '#636c79' }}>Start datum</p>
                                            <p style={{ marginLeft: 'auto' }}>{new Date(campaignStore.selectedCampaign.startDate!).toDateString()}</p>
                                        </div>
                                        <div style={{ display: 'flex', width: '100%', margin: '15px 0' }}>
                                            <p style={{ minWidth: '20%', color: '#636c79' }}>Eind datum</p>
                                            <p style={{ marginLeft: 'auto' }}>{new Date(campaignStore.selectedCampaign.endDate!).toDateString()}</p>
                                        </div>
                                        
                                        <Button label="Reset selected campaign" onClick={() => btnResetSelectedCampaign()} /><br /><br />
                                        { 
                                            campaignStore.selectedCampaign.labels.includes(devote_user_name) && devote_user_name != 'Not known' ?
                                            <Button label="Remove me from campaign" severity="warning" onClick={() => removeFromCampaign() } />
                                            :
                                            <Button label="Add me to campaign" severity="success" onClick={() =>  addToCampaign() } />

                                        }
                                    </div>
                                </div>


                            </div>
                        </Card>   
                        <Card 
                            className="linkbuidling-overview-cost" 
                            title="Budget Overview" 
                            subTitle='View your budget details'
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: subLoadingDataBudget ? 'center' : 'flex-start',
                                alignItems: subLoadingDataBudget ? 'center' : 'flex-start',
                            }}>
                                {subLoadingDataBudget ?
                                <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
                                : 
                                    <BudgetChart 
                                        campaignBudgets={campaignBudgets} />
                                    
                                }
                            </div>
                        </Card>
                    </div>

                    <div className="linkbuilding-campaign-keywords">
                        <Card title="Keywords" subTitle='View your keywords'>
                            <div style={{
                                display: 'flex',
                                justifyContent: subLoadingDataKeywords ? 'center' : 'flex-start',
                                alignItems: subLoadingDataKeywords ? 'center' : 'flex-start',
                            }}>
                                {subLoadingDataKeywords ?
                                <div>
                                <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
                                </div>
                                : 
                                <div style={{width: '100%'}}>
                                    <DataTable 
                                        value={campaignStore.selectedCampaign.campaignPageLinks}
                                        header={campaignPageLinksHeader}
                                        onRowEditComplete={(row) => updateCampaignPageLink(row)}
                                        className="p-datatable-sm p-datatable-gridlines"
                                        editMode="row"
                                        dataKey="id"
                                        showGridlines={true}
                                        stripedRows={true}
                                        style={{width: '100%'}}
                                        paginator
                                        rows={5}
                                    >
                                        <Column field="pageLinkText" header="Link" sortable editor={(options) => dataCampaignPageLinkTextEditor(options)}></Column>
                                        <Column field="allocation" header="Target" sortable editor={(options) => numberEditor(options)}></Column>
                                        <Column field="keywordCount" header="Current" sortable></Column>                                     
                                        <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                        <Column header="" body={campaignPageLinksActionBodyTemplate}></Column>
                                    </DataTable>

                                    <Button label={"Refresh"} onClick={() => __refreshCampaignPageLinks()} />
                                </div>
                                }
                            </div>
                        </Card>
                    </div>                   

                    <div className="linkbuilding-campaign-linkplacements">
                        <Card 
                            title="Linkplacements" 
                            subTitle='View your linkplacements for this campaign'
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: subLoadingDataLinkplacements ? 'center' : 'flex-start',
                                alignItems: subLoadingDataLinkplacements ? 'center' : 'flex-start',
                            }}>
                                { subLoadingDataLinkplacements ?
                                    <BlinkBlur color="#d39c68" size="small" text="" textColor="" />
                                : 
                                    <div style={{width: '100%'}}>
                                        { !campaignStore.selectedCampaign.campaignPageLinks ?
                                            <div><h1>Eerst zijn er campaign page links nodig</h1></div>
                                        :
                                            <div style={{width: '100%'}}>
                                                <DataTable 
                                                    value={campaignStore.selectedCampaign.linkplacements}
                                                    header={campaignLinkplacementHeader}
                                                    className="p-datatable-sm p-datatable-gridlines"
                                                    dataKey="id"
                                                    showGridlines={true}
                                                    stripedRows={true}
                                                    style={{width: '100%'}}
                                                    paginator
                                                    rows={20}
                                                >
                                                    <Column field="" header=""></Column> 
                                                    <Column field="placedOnUrl" header="Link" sortable></Column>
                                                    <Column field="status" header="Status" sortable></Column>
                                                    <Column header="Publish date" body={(rowData) => displayDateBodyTemplate(rowData, 'publishDate')}></Column>
                                                    {/* <Column field="publishDate" header="Date" sortable></Column> */}
                                                    {/* <Column field="pageLinkText" header="Link" sortable editor={(options) => dataCampaignPageLinkTextEditor(options)}></Column> */}
                                                    <Column header="" body={campaignLinkplacementBodyTemplate}></Column>
                                                </DataTable>
                                                <Button label={"Refresh"} onClick={() => __refreshCampaignLinkPlacements()} />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </Card>
                    </div>
                    
                    <div className="linkbuilding-campaign-changelog" style={{ margin: '50px 0 0 0'}}>
                        <DisplayChangeLogItems  objectId={campaignStore.selectedCampaign.id} />
                    </div>
                </div>
            }
            
            <Dialog header='Create new campaign' visible={newCampaignDialog} onHide={() => { setNewCampaignDialog(false) }}>
                <CreateCampaignDialogComponent setDialogVisible={() => setNewCampaignDialog(false)} />
            </Dialog>
            <Dialog header='Edit campaign' visible={editCampaignDialog} onHide={() => { setEditCampaignDialog(false); }}>
                <EditCampaignDialogComponent setDialogVisible={() => setEditCampaignDialog(false)}/>
            </Dialog>

            <Dialog header={messageDialogContent.title || 'Ter info'} visible={messageDialog} onHide={() => { setMessageDialog(false) }}>
                <MessageDialogComponent setDialogVisible={messageDialog} props={messageDialogContent}/>
            </Dialog>


            {/* This confirm-dialog is empty by intent, it's filled by the confirm function. */}
            <ConfirmDialog />
        </>
    );
}
export default LinkbuildingCampaign;