/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SidebarComponent, { SidebarItemProps } from "../../../../components/sidebar/sidebar.component";
import "./app-layout.styles.scss"
import { useAuthStore, useProjectStore, useCampaignStore, useThemeStore } from "../../../../store";
import ProjectSelectDialog from "../../../../components/project-select-dialog/project-select-dialog.component";
import { Dialog } from "primereact/dialog";
import './app-layout.styles.scss';

const AppLayout = () => {

    const authStore = useAuthStore();
    const projectStore = useProjectStore();
    const themeStore = useThemeStore();
    const campaignStore = useCampaignStore();
    const location = useLocation();

    const logout = () => {
        // Reset application state.
        authStore.reset();
        projectStore.reset();
        campaignStore.reset();

        // Remove the access and refresh tokens from local storage.
        localStorage.removeItem('devote_access_token');
        localStorage.removeItem('devote_refresh_token');
    }

    const [sidebarItems, setSidebarItems] = React.useState<SidebarItemProps[]>([
        // Top Items
        { index: 1, title: 'Home', icon: 'pi pi-home', color: '#e8e8e8', active: false, inFooter: false, link: '/home' },
        
        // Middle Items
        { index: 3, title: 'Springbok-GPT', icon: 'fa-solid fa-comment-dots', color: '#8187FF', active: false, inFooter: false, link: '/springbok-gpt' },
        { index: 4, title: 'Connectors', icon: 'pi pi-sitemap', color: '#F08418', active: false, inFooter: false, link: '/connectors' },
        { index: 5, title: 'Advantiv', icon: 'fa-solid fa-arrows-to-eye', color: '#15A18A', active: false, inFooter: false, link: '/advantiv' },
        { index: 6, title: 'CloudFormer', icon: 'pi pi-cloud', color: '#DDFF00', active: false, inFooter: false, link: '/cloudformer' },
        { index: 7, title: 'Linkbuilding', icon: 'pi pi-link', color: '#B9A574', active: false, inFooter: false, link: '/linkbuilding' },

        // Footer Items
        { index: 12, title: 'Admin', icon: 'pi pi-key', color: '#e8e8e8', active: false, inFooter: true, link: '/admin' },
        { index: 13, title: 'Settings', icon: 'pi pi-cog', color: '#e8e8e8', active: false, inFooter: true, link: '/settings' },
        { index: 14, title: 'Help', icon: 'pi pi-question-circle', color: '#e8e8e8', active: false, inFooter: true, link: '/help' },
        { index: 15, title: 'Logout', icon: 'pi pi-sign-out', color: '#e8e8e8', active: false, inFooter: true, link: '/login', onClick: () => {logout();} },
    ]);

    // This effect is used to setup the sidebar items when the end-user accesses a page using the URL.
    React.useEffect(() => {
        const newSidebarItems: SidebarItemProps[] = []

        let currentActiveItem = sidebarItems.find(item => item.active);
        let nextActiveItem = sidebarItems.find(item => location.pathname.includes(item.link));

        if (currentActiveItem) {
            currentActiveItem.active = false;
        }
        if (nextActiveItem) {
            nextActiveItem.active = true;
        }

        if ((currentActiveItem === nextActiveItem) && nextActiveItem) {
            newSidebarItems.push(nextActiveItem);
        } else {
            if (currentActiveItem) {
                newSidebarItems.push(currentActiveItem);
            }
            if (nextActiveItem) {
                newSidebarItems.push(nextActiveItem);
            }
        }

        const sidebarItemsWithoutCurrentAndNext = sidebarItems.filter(item => item.index !== currentActiveItem?.index && item.index !== nextActiveItem?.index);
        newSidebarItems.push(...sidebarItemsWithoutCurrentAndNext);
        
        // Sort in ascending order by index.
        newSidebarItems.sort((a, b) => a.index - b.index);

        // Update the sidebar items.
        setSidebarItems(newSidebarItems);
    }, [location]);

    return (
        <div className={themeStore.mode === 'dark' ? "dark" : "light"}>
            <SidebarComponent items={sidebarItems} />
            <div className={themeStore.sidebar === 'collapsed' ? "layout layout-collapsed" : "layout layout-expanded" }>
                {
                    projectStore.selectedProject ? 
                    <Outlet />
                    : 
                    <Dialog className="project-select-dialog" header='Please select a project' visible={true} onHide={() => {}} > 
                        <ProjectSelectDialog />
                    </Dialog>
                }
            </div>
        </div>
    );
};

export default AppLayout;
