import React from "react";
import { ILinkPlacement, IWebsite } from "../models/linkplacement";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { IProject } from "../../../interfaces/project.interface";
import { useLinkbuildingCampaignStore, useProjectStore } from "../../../store";
import { ApiResponse } from "../../../api/base/api-response";
import { Dropdown } from "primereact/dropdown";
import { LinkPlacementCurrentStatus } from "../models/enums";

const EditLinkplacementDialogComponent = (props: { linkplacement: ILinkPlacement | undefined, onHide: () => void, website: IWebsite|undefined }) => {

    const linkbuildingService = new LinkbuildingService();
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const linkbuildingCampaignStore = useLinkbuildingCampaignStore();

    // FORM FIELDS
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
    const [comment, setComment] = React.useState<string>('');
    const [price, setPrice] = React.useState<number>(0);
    const [publishDate, setPublishDate] = React.useState<Date>(new Date());
    const [placedOnUrl, setPlacedOnUrl] = React.useState<string>('');
    const [status, setStatus ] = React.useState<number>(1);
    const [status_text, setStatusText] = React.useState<string>('');

    const [website, setWebsite] = React.useState<IWebsite | undefined>(props.website);
    const [linkplacement, setLinkplacement] = React.useState<ILinkPlacement | undefined>(props.linkplacement);
    const onHide = props.onHide;

    const linkplacementStatuses = Object.keys(LinkPlacementCurrentStatus) 
        .filter((key) => isNaN(Number(key))) // Filter de reverse mapping (getallen als keys)
        .map((key) => ({
            label: key, // Enum naam (e.g., "Requested")
            value: LinkPlacementCurrentStatus[key as keyof typeof LinkPlacementCurrentStatus], // Enum waarde (e.g., 0)
        }));

    React.useEffect(() => {
        if (linkplacement) {
            setComment(linkplacement.comment);
            setPrice(linkplacement.price);
            setPublishDate(linkplacement.publishDate);
            setPlacedOnUrl(linkplacement.placedOnUrl);
            setStatus(linkplacement.status_number);
            setStatusText(linkbuildingService.getLinkbuildingStatusText(linkplacement.status_number, linkplacement.publishDate))
            console.log(linkplacement)
        }
    }, [linkplacement]);
    

    // API REQUESTS
    const createOrUpdateLinkplacement = () => {
        setFormIsSubmitting(true);
        let item = createLinkplacementObject();
        if (linkplacement?.id && linkplacement?.id != 'new') {
            
            linkbuildingService.updateCampaignLinkplacement(selectedProject.id, linkbuildingCampaignStore.selectedCampaign.id, item).then((response:ApiResponse<ILinkPlacement>) => {
                if(response.hasError()){
                    console.log(response.getError());
                }
                response.getBody().then((data) => {
                    let campaign = linkbuildingCampaignStore.selectedCampaign
                    console.log(campaign)
                    let newLinkplacement = {
                        ... linkplacement,
                    }
                    newLinkplacement.comment = data.comment;
                    newLinkplacement.price = data.price;
                    newLinkplacement.publishDate = data.publishDate;
                    newLinkplacement.placedOnUrl = data.placedOnUrl;
                    newLinkplacement.status_number = data.status_number;
                    // newLinkplacement.status_text = data.status_text;
                    newLinkplacement.websiteId = data.websiteId;
                    
                    console.log(newLinkplacement)
                    
                    setLinkplacement(newLinkplacement);
                });
                setFormIsSubmitting(false);
                onHide();
            });
        } else {
            linkbuildingService.createCampaignLinkplacement(selectedProject.id, linkbuildingCampaignStore.selectedCampaign.id, item).then((response:ApiResponse<ILinkPlacement>) => {
                if(response.hasError()){
                    console.log(response.getError());
                }
                response.getBody().then((data) => {
                    let campaign = linkbuildingCampaignStore.selectedCampaign;
                    if(campaign.linkPlacements == undefined ){
                        campaign.linkPlacements = [];
                    }
                    campaign.linkPlacements.push(data);
                    linkbuildingCampaignStore.setSelectedCampaign(campaign);
                    var host = window.location.protocol + "//" + window.location.host;
                    window.location.href = host + "/linkbuilding/linkplacement/" + data.id;
                });
                setFormIsSubmitting(false);
                onHide();
            });
        }
    }

    // BTN ACTIONS
    const btnClickLinkplacementUpdate = () => {
        createOrUpdateLinkplacement();
    }

    const btnClickCreateLinkplacement = () => {
        createOrUpdateLinkplacement();
    }

    const updateStatus = (status: number) => {
        setStatus(status);

    }

    const createLinkplacementObject = (): ILinkPlacement => {
        let item =  {
            comment: comment,
            price: price,
            publishDate: publishDate,
            placedOnUrl: placedOnUrl,
            status_number: status,
        }
        console.log(item)   
        if (linkplacement?.id && linkplacement?.id != 'new') {
            item['id'] = linkplacement.id;
            item['websiteId'] = linkplacement.websiteId;
        }else{
            item['websiteId'] = website?.id;
        }
        return item as ILinkPlacement;
    }

    return (
        <>
        <div>
            <div style={{ width: '100%' }}>
                <div style={{ minWidth: '200px' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>Url: {website?.domainUrl} </p>
                    <InputText
                        style={{ width: '100%' }}
                        value={placedOnUrl} 
                        placeholder="https://www.example.com"
                        onChange={(e) => setPlacedOnUrl(e.target.value)} />
                </div>
                <div style={{ marginTop: '25px', minWidth: '200px' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>Comments:</p>
                    <InputTextarea
                        style={{ width: '100%' }}
                        value={comment} 
                        onChange={(e) => setComment(e.target.value)} />
                </div>
                <div style={{ marginTop: '25px', minWidth: '200px' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>Price:</p>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={price} 
                        onChange={(e) => { if (e.value !== null) setPrice(e.value); console.log(e.value); }} />
                </div>
                <div style={{ marginTop: '25px', minWidth: '200px' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>Publish date:</p>
                    <Calendar
                        style={{ width: '100%' }}
                        value={publishDate} 
                        onChange={(e) => {setPublishDate(e.target.value as Date )}} 
                        showIcon
                        showButtonBar
                        dateFormat="d-m-yy" />
                </div>
                <div style={{ marginTop: '25px', minWidth: '200px' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>Status:</p>
                    <Dropdown
                        style={{ width: '100%' }}
                        value={status} 
                        options={linkplacementStatuses}
                        onChange={(e) => updateStatus(e.target.value)} />
                </div>


            </div>
            <div>
            { linkplacement?.id == 'new' ? 
                <button onClick={() => btnClickCreateLinkplacement()}>Create</button> 
            :
                <div>
                    <button onClick={() => onHide() }>Cancel</button>
                    <button onClick={() => btnClickLinkplacementUpdate()}>Save</button>
                </div>
            }  
            </div>  
        </div>
        </>
    )
}
export default EditLinkplacementDialogComponent;