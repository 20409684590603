import React from "react";
import { IContact } from "../models/contact";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ICampaignBudget } from "../models/campaign";
import { Calendar } from "primereact/calendar";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { IProject } from "../../../interfaces/project.interface";
import { useProjectStore, useLinkbuildingCampaignStore } from "../../../store";
import { start } from "repl";

const BudgetFormDialog = (budgetInput: any) => {

    const linkbuildingService = new LinkbuildingService();
    const selectedProject: IProject = useProjectStore(store => store.selectedProject);
    const campaignStore = useLinkbuildingCampaignStore();
    
    const [budget, setBudget] = React.useState<ICampaignBudget>({} as ICampaignBudget);
    const [startDate, setStartDate] = React.useState<Date>(new Date());
    const [endDate, setEndDate] = React.useState<Date>(new Date());
    const [price, setPrice] = React.useState<number | null>(0);
    const [comment, setComment] = React.useState<string>('');

    const [errors, setErrors] = React.useState<string>('');

    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
    let setDialogVisible = budgetInput.setDialogVisible;
    React.useEffect(() => {
        console.log(budgetInput)
        if (budgetInput.budget != null) {
            setBudget(budgetInput.budget);
            setStartDate(new Date(budgetInput.budget.startDate));
            setEndDate(new Date(budgetInput.budget.endDate));
            setPrice(budgetInput.budget.price);
            setComment(budgetInput.budget.comment);
        }else{
            setStartDate(new Date(campaignStore.selectedCampaign.startDate));
            setEndDate(new Date(campaignStore.selectedCampaign.endDate));
        }

    }, [budgetInput.budget]);

    //BUTTON ACTIONS
    const submit = () => {
        let campaign = campaignStore.selectedCampaign;
        let campaignStartData =new Date(campaign.startDate);
        let campaignEndData = new Date(campaign.endDate);
        console.log(campaign);
        console.log(startDate); 
        console.log(endDate);
        if(startDate == null || endDate == null || price == null){
            setErrors('Please fill in all fields');
            return;
        }
        if (startDate > endDate) {
            setErrors('Start date cannot be greater than end date');
            return;
        }
        if (startDate < campaignStartData || endDate > campaignEndData) {
            setErrors('Start date and end date should be within the campaign duration of ' + campaignStartData.toLocaleDateString() + ' to ' + campaignEndData.toLocaleDateString());
            return;
        }
        if(budget.id != null){
            updateBudget();
        }else{
            createBudget();
        }
    }

    const createObject = () => {
        return {
            startDate: startDate,
            endDate: endDate,
            price: price,
            comment: comment
        } as unknown as ICampaignBudget;
    }


    const updateBudget = () => {
        let budgetObject = createObject();
        budgetObject['id'] = budget.id;
        linkbuildingService.updateCampaignBudget(
            selectedProject.id, 
            campaignStore.selectedCampaign.id, 
            budgetObject).then((response) => {
            if(response.hasError()){
                console.error(response.getError());
            }else{
                response.getBody().then((data) => {
                    setDialogVisible(false);
                });
            }
        });
    }
    const createBudget = () => {
        let budgetObject = createObject();
        linkbuildingService.createCampaignBudget(
            selectedProject.id, 
            campaignStore.selectedCampaign.id, 
            budgetObject).then((response) => {
            if(response.hasError()){
                console.error(response.getError());
            }else{
                response.getBody().then((data) => {
                    setDialogVisible(false);
                });
            }
        });
    }

    return (
        <div style={{ width: '350px' }}>
            { errors != '' ? <p style={{ color: 'red' }}>{errors}</p> : null }
            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>StartDate:</p>
                <Calendar
                    style={{ width: '100%' }}
                    value={new Date(startDate)}
                    onChange={(e) => {setStartDate(e.target.value as Date )}}
                />
            </div>

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>EndDate:</p>
                <Calendar
                    style={{ width: '100%' }}
                    value={new Date(endDate)}
                    onChange={(e) => {setEndDate(e.target.value as Date )}}
                />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Budget</p>
                <InputNumber
                    style={{ width: '100%' }}
                    value={price} onChange={(e) => setPrice(e.value)} />
            </div>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p>Comment</p>
                <InputText
                    style={{ width: '100%' }}
                    value={comment}
                    onChange={(e) => {setComment(e.target.value)}} />
            </div>

            <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => setDialogVisible(false)}/>

                {
                    formIsSubmitting ?
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) 
                    :
                        <div>
                        { budget.id != null ? 
                            ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Update' onClick={() => submit()} />)
                        :
                            ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submit()} />)
                        }
                        </div>
                }
            </div>
        </div>
    )
}

export default BudgetFormDialog; 