import React from "react";


const MessageDialogComponent = ({ setDialogVisible, props}) => {
    
    React.useEffect(() => {

    }, []);

    return (
        <div style={{ width: '350px' }}>

            <p>{props.message}</p>
            
        </div>
    )
}

export default MessageDialogComponent;