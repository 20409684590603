import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

const textEditor = (options) => {
    return <InputText style={{ width: '80%' }} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}
const numberEditor = (options, min = 0, max = 100) => {
    return <InputNumber className="inputOnDataTableSmall" min={min} max={max} type="decimal" locale="nl-NL" maxFractionDigits={4} value={options.value} onChange={(e) => options.editorCallback(e.value)} />;
}
const currencyEditor = (options) => {
    return <InputNumber className="inputOnDataTableSmall" value={options.value} onChange={(e) => options.editorCallback(e.value)} required autoFocus mode="currency" currency="EUR" locale="nl-NL" maxFractionDigits={0} />
}

const dropDownEditor = (rowData: any, options, value=undefined) => {
    if(value == undefined) {
        value = rowData.value;
    }
    return (
        <Dropdown
                style={{ width: '100%' }}
                value={value}
                options={options}
                filter
                filterBy="label"
                placeholder={rowData.field}
                onChange={(e) => rowData.editorCallback(e.target.value)}
            />
    )
}

export { textEditor, numberEditor, currencyEditor, dropDownEditor};