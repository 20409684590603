import React from "react";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { useProjectStore } from "../../../store";
import { IProject } from "../../../interfaces/project.interface";
import { ApiResponse } from "../../../api/base/api-response";
import { ICampaign } from "../models/campaign";
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { IPartner, IWebsiteCRUD } from "../models/linkplacement";


const CreatePartnerDialogComponent = ({ setDialogVisible }) => {
    
    React.useEffect(() => {

    }, []);
    
    // STORES.

    
    // FORM FIELDS
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
    const [name, setName] = React.useState<string>('');
    const [comment, setComment] = React.useState<string>('');
    // BUTTON ACCTIONS
    const submitCreateNewWebsiteForm = () => {
        // setFormIsSubmitting(true);

        const linkbuildingService = new LinkbuildingService();
        linkbuildingService.createPartner({
            name: name,
            comment: comment,
        }).then((apiResponse: ApiResponse<IPartner>) => {
            if (apiResponse.hasError()) {
                // Todo: implement error handling.
            } else {
                // location.reload();
            }
        })
    }

    return (
        <div style={{ width: '350px' }}>

            <div style={{ marginTop: '25px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Name:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Comments:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={comment} onChange={(e) => setComment(e.target.value)} />
            </div>


            <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => setDialogVisible(false)}/>

                {
                    formIsSubmitting ?
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) :
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submitCreateNewWebsiteForm()} />)
                }
                
             </div>
        </div>
    )
}

export default CreatePartnerDialogComponent;