import React from "react";
import { LinkbuildingService } from "../../../api/linkbuilding-service";
import { useProjectStore } from "../../../store";
import { IProject } from "../../../interfaces/project.interface";
import { ApiResponse } from "../../../api/base/api-response";
import { ICampaign } from "../models/campaign";
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { IWebsiteCRUD } from "../models/linkplacement";


const WebsiteFormDialog = ({ closeDialog, website }) => {
    
    React.useEffect(() => {
        if (website) {
            setFormType('update');

            setDomainUrl(website.domainUrl);
            setType(website.type);
            setStatus(website.status);
            setComments(website.comments);
            setPrice(website.price);
        }
    }, [website]);
    
    // STORES.

    
    // FORM FIELDS
    const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
    const [formType, setFormType] = React.useState<string>('create');
    const [domainUrl, setDomainUrl] = React.useState<string>('');
    const [type, setType] = React.useState<number>(0);
    const [status, setStatus] = React.useState<number>(0);
    const [comments, setComments] = React.useState<string>('');
    const [price, setPrice] = React.useState<number | null>(0);

    // BUTTON ACTIONS
    const submitForm = () => {
        setFormIsSubmitting(true);

        if (formType === 'create') {
            createWebsite();
        } else {
            updateWebsite();
        }
    }

    // API CALLS
    const createWebsite = () => {
        const linkbuildingService = new LinkbuildingService();
        const websiteData = {
            domainUrl: domainUrl,
            type: type,
            status: status,
            comments: comments,
            price: price ? price : 0
        };

        const apiCall = linkbuildingService.createWebsite(websiteData);

        apiCall.then((apiResponse: ApiResponse<IWebsiteCRUD>) => {
            setFormIsSubmitting(false);
            if (apiResponse.hasError()) {
                // Todo: implement error handling.
            } else {
                closeDialog();
            }
        });
    }

    const updateWebsite = () => {
        const linkbuildingService = new LinkbuildingService();
        const websiteData = {
            type: type,
            status: status,
            comments: comments,
        };

        const apiCall = linkbuildingService.updateWebsite(website.id, websiteData);

        apiCall.then((apiResponse: ApiResponse<IWebsiteCRUD>) => {
            setFormIsSubmitting(false);
            if (apiResponse.hasError()) {

            }
            else {
                closeDialog();
                // location.reload();
            }
        }

        );
    }

    return (
        <div style={{ width: '350px' }}>

            {formType === 'create' && (
                <div style={{ marginTop: '25px', minWidth: '200px' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>DomainUrl:</p>
                    <InputText
                        style={{ width: '100%' }}
                        value={domainUrl} onChange={(e) => setDomainUrl(e.target.value)} />
                </div>
            )}

            <div style={{ marginTop: '10px', minWidth: '200px' }}>
                <p style={{ fontSize: '12px', marginBottom: '3px' }}>Comment:</p>
                <InputText
                    style={{ width: '100%' }}
                    value={comments} onChange={(e) => setComments(e.target.value)} />
            </div>

            <div style={{ marginTop: '35px', display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative", width: "100%" }}>
                <Button style={{ marginRight: "5px" }} className="p-button-secondary" label="Cancel" onClick={() => closeDialog()}/>

                {
                    formIsSubmitting ?
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" loading={true} />) :
                        ( <Button style={{width: "100px", textAlign: 'center'}} className="p-button-success" label='Submit' onClick={() => submitForm()} />)
                }
                
             </div>
        </div>
    )
}

export default WebsiteFormDialog;